/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { connect } from "react-redux"
import { movePage } from "../redux/reducers/main"
import { Link } from "gatsby"

const TextLink = props => {
  const { text, linkAction, to } = props

  if (to) {
    return (
      <div
        css={css`
          margin: 5px;
        `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: inherit;
          `}
          to={to}
        >
          {text}
        </Link>
      </div>
    )
  } else {
    const handleClick = e => {
      if (linkAction) {
        linkAction()
      }
      e.preventDefault()
      e.stopPropagation()
    }

    return (
      <li
        css={css`
          margin: 5px;
        `}
        onClick={handleClick}
        onKeyDown={() => {}}
        role="none"
      >
        {text}
      </li>
    )
  }
}

const NavMenu = props => {
  const { movePage } = props
  return (
    <nav
      css={css`
        font-family: FGMediumHankaku, sans-serif;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 101;
      `}
    >
      <ul
        css={css`
          display: flex;
          flex-direction: row;
          margin: 30px;
          list-style: none;
          @media screen and (max-width: 600px) {
            color: black;
          }
          @media screen and (min-width: 600px) and (max-width: 1600px) {
            color: white;
          }
          @media screen and (min-width: 1600px) {
            color: black;
          }
        `}
      >
        <TextLink text="会社案内" linkAction={() => movePage(1)} />
        <TextLink text="ブログ" to="/blog" />
        <TextLink text="お問い合わせ" linkAction={() => movePage(2)} />
      </ul>
    </nav>
  )
}

const mapStateToProp = state => ({
  main: state.main,
})

const mapDispatchToProps = dispatch => ({
  movePage: (...a) => dispatch(movePage(...a)),
})

export default connect(mapStateToProp, mapDispatchToProps)(NavMenu)
