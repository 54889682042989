import React from "react"
import styled from "@emotion/styled"
import Form from "./Form"
import FormDialog from "./FormDialog"
import _ from "lodash"
import axios from "axios"
import { Alert, AlertTitle } from "@material-ui/lab"
import { movePage } from "../redux/reducers/main"
import { connect } from "react-redux"

const initialState = {
  form: {
    name: {
      value: "",
      hasError: false,
      message: "",
    },
    email: {
      value: "",
      hasError: false,
      message: "",
    },
    message: {
      value: "",
      hasError: false,
      message: "",
    },
  },
  dialog: false,
  disabled: true,
  error: false,
  success: false,
}

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = _.cloneDeep(initialState)
    this.handleChange = this.handleChange.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e, key) {
    const { form } = this.state
    // console.log(key, form)
    form[key].value = e.target.value
    const disabled = this.formCheck(form)
    this.setState({ form, disabled })
  }

  formCheck(form) {
    let errorCount = 0
    Object.keys(form).forEach(key => {
      // console.log(key)
      let re
      switch (key) {
        case "name":
        case "message":
          re = /^.+$/
          break
        case "email":
          re = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
          break
        default:
          break
      }
      const value = form[key].value.replace(/\n/g, " ")
      if (re.test(value)) {
        form[key].hasError = false
        form[key].message = ""
      } else {
        errorCount++
        form[key].hasError = true
        form[key].message = "入力内容を確認してください"
      }
    })
    return errorCount !== 0
  }

  toggleDialog() {
    const dialog = !this.state.dialog
    this.setState({ dialog })
  }

  clearForm(result) {
    const state = _.cloneDeep(initialState)
    if (result === true) {
      state.success = true
      state.error = false
    }
    if (result === false) {
      state.error = true
      state.success = false
    }
    this.setState(state)
  }

  handleSubmit() {
    const { form } = this.state
    const url = "https://submit-form.com/W6w-qdHwNzcc4KUEV3yLq"

    const thisComponent = this

    axios
      .post(url, {
        name: form.name.value,
        email: form.email.value,
        message: form.message.value,
      })
      .then(function (res) {
        // console.log(res)
        thisComponent.setState({
          success: true,
          error: false,
          dialog: false,
          disabled: true,
        })
      })
      .catch(function (err) {
        console.error(err)
        thisComponent.setState({
          error: true,
          success: false,
          dialog: false,
          disabled: true,
        })
      })
  }

  render() {
    const RootDiv = styled.div`
      width: 100%;
      height: 100%;
      position: relative;
    `

    const MainDiv = styled.div`
      position: absolute;
      top: 0;
      left: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      width: calc(100% - 60px);
    `

    const FormDiv = styled.div`
      padding: 20px;
    `

    return (
      <RootDiv>
        <MainDiv>
          <FormDiv>
            {this.state.success === true && (
              <Alert severity="success">
                <AlertTitle>メッセージの送信ありがとうございました</AlertTitle>
                {this.state.form.email.value}
                へご連絡致しますので今しばらくお待ち下さい。
              </Alert>
            )}
            {this.state.errir === true && (
              <Alert severity="error">
                <AlertTitle>メッセージの送信ができませんでした</AlertTitle>
                恐れ入りますが、もう一度お試しください
              </Alert>
            )}
            <Form
              handleChange={this.handleChange}
              form={this.state.form}
              toggleDialog={this.toggleDialog}
              disabled={this.state.disabled}
            />
            <FormDialog
              open={this.state.dialog}
              form={this.state.form}
              toggleDialog={this.toggleDialog}
              handleSubmit={this.handleSubmit}
            />
          </FormDiv>
        </MainDiv>
      </RootDiv>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  movePage: (...a) => dispatch(movePage(...a)),
})

export default connect(null, mapDispatchToProps)(Contact)
