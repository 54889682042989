import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  Grid,
} from "@material-ui/core"

const FormDialog = props => {
  const { open, form, toggleDialog, handleSubmit } = props

  const msgArr = form.message.value.split("\n")

  return (
    <Dialog onClose={toggleDialog} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title" onClose={toggleDialog}>
        お問い合わせフォーム送信確認
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="caption">お名前</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>{form.name.value}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">ご連絡先メールアドレス</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom>{form.email.value}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">ご用件</Typography>
          </Grid>

          <Grid item xs={12}>
            {msgArr.map((line, i) => (
              <Typography gutterBottom key={i}>
                {line}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          送信
        </Button>
        <Button onClick={toggleDialog} color="secondary">
          入力画面に戻る
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormDialog
