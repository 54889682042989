/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import portrait from "../img/kazue.png"
import siile from "../img/siile.png"
import logo from "../img/logo.png"
import workspaceimg from "../img/workspace.png"
import workspaceimg2 from "../img/workspace2.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faBlog } from "@fortawesome/free-solid-svg-icons"
import languages from "./Languages"
import { Link } from "gatsby"

export default [
  {
    index: 2,
    height: 400,
    color: "#2f4f4f",
    content: (
      <div>
        {/* <img src={portrait} /> */}
        <img src={logo} alt="Sasataki Kikaku Logo" />
      </div>
    ),
    page: 0,
  },
  {
    index: 1,
    height: 450,
    color: "#ffe4b5",
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #666633;
          padding: 30px;
        `}
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" />
        <h3>所在地</h3>
        <p>神奈川県横浜市青葉区</p>
      </div>
    ),
  },
  {
    index: 0,
    height: 1150,
    color: "white",
    content: (
      <div>
        <img
          css={css`
            width: 100%;
          `}
          src={portrait}
          alt="portrait"
        />
      </div>
    ),
  },
  {
    index: 3,
    height: 800,
    color: "#b0e0e6",
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #696969;
          padding: 30px;
        `}
      >
        <h2>ごあいさつ</h2>
        <h4>ウェブをもっと身近に楽しく</h4>
        <p
          css={css`
            line-height: 1.5rem;
          `}
        >
          ササタニキカクです。ウェブ技術は日々進化していますが、同時によりたくさんのことができるようになってきています。
          ウェブでものを売りたい、現在進行中の開発プロジェクトで人手がたりないなど、何かお手伝いができることがあれば相談だけでも是非ご連絡ください！
        </p>
      </div>
    ),
  },
  {
    index: 4,
    height: 950,
    color: "lightgray",
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #696969;
          padding: 30px;
        `}
      >
        <h2>略歴</h2>
        <p
          css={css`
            line-height: 1.5rem;
          `}
        >
          外資系証券会社にて証券決済周りのシステム導入、社内インフラのサポートを担当•その後、４年間渡米。現地ソフトウェア企業にソフトウェアエンジニアとして、航空機業界・鉄鋼業界のシステム開発に携わる。
          <br />
          早稲田大学大学院　先進理工学研究科電気・情報生命専攻修了、
          当時の研究テーマは機械学習
          <br />
          大学時代は美術系サークルに所属し、ファッション・デザイン・芸術文化に嗜好あり
        </p>
      </div>
    ),
  },
  {
    index: 5,
    css:
      "url(https://images.pexels.com/photos/1738986/pexels-photo-1738986.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    height: 950,
    color: "#d8bfd8",
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #696969;
          padding: 30px;
        `}
      >
        <h2>おしごと事例</h2>
        <h4
          css={css`
            line-height: 1rem;
          `}
        >
          Siile Web版買物代行プラットフォーム
        </h4>
        <img
          css={css`
            width: 90%;
          `}
          src={siile}
          alt="siile"
        />
        <p
          css={css`
            line-height: 1.5rem;
          `}
        >
          30分で配達するオンデマンド買物代行プラットフォームを提供するSiileさんで、web版のプラットフォームを担当しています。
        </p>
      </div>
    ),
    url: "https://shop.siile.jp",
  },
  {
    index: 6,
    color: "#e0ffff",
    height: 300,
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #696969;
          padding: 30px;
        `}
      >
        <h3
          css={css`
            line-height: 1.2rem;
          `}
        >
          お問い合わせはこちら！
        </h3>
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
      </div>
    ),
    page: 2,
  },
  {
    index: 7,
    color: "darkpink",
    height: 950,
    content: (
      <div>
        <img src={workspaceimg2} alt="Workspace2" />
      </div>
    ),
  },
  {
    index: 8,
    color: "yellow",
    height: 300,
    content: (
      <div
        css={css`
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #696969;
          padding: 30px;
        `}
      >
        <Link
          css={css`
            color: inherit;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
          to="/blog"
        >
          <h2
            css={css`
              line-height: 1.2rem;
            `}
          >
            ブログはこちら！
          </h2>
          <FontAwesomeIcon icon={faBlog} size="2x" />
        </Link>
      </div>
    ),
  },
  {
    index: 9,
    color: "lightpink",
    height: 300,
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: calc(100% - 60px);
          width: calc(100% - 60px);
          font-family: FGMediumHankaku, sans-serif;
          color: #696969;
          padding: 30px;
        `}
      >
        <h2
          css={css`
            line-height: 1.2rem;
          `}
        >
          ツイッターはこちら！
        </h2>
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </div>
    ),
    url: "https://twitter.com/sasatanikikaku",
  },
  {
    index: 10,
    color: "#bc8f8f",
    height: 900,
    content: (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: calc(100% - 40px);
          width: calc(100% - 40px);
          font-family: FGMediumHankaku, sans-serif;
          color: white;
          padding: 20px;
        `}
      >
        <h2>プログラミング言語</h2>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
          `}
        >
          {languages.map((lang, idx) => {
            return (
              <div
                key={idx}
                css={css`
                  margin: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  jusify-content: center;
                `}
              >
                <p>{lang.text}</p>
                <FontAwesomeIcon icon={lang.icon} size="3x" />
              </div>
            )
          })}
        </div>
      </div>
    ),
  },
  {
    index: 11,
    color: "darkpink",
    height: 950,
    content: (
      <div>
        <img src={workspaceimg} alt="Workspace" />
      </div>
    ),
  },
]
