/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { movePage } from "../redux/reducers/main"
import { openExternalLink } from "../util/func"

const LinkIcon = props => {
  const { icon, clickAction } = props
  return (
    <div
      css={css`
        margin: 10px 0;
        background-color: #333333;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.9));
      `}
      onKeyDown={() => {}}
      onClick={clickAction}
      role="none"
    >
      <FontAwesomeIcon icon={icon} size="lg" />
    </div>
  )
}

const LeftMenu = props => {
  const { movePage } = props

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 60px;
        background-color: #666666;
        opacity: 0.8;
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 100;
      `}
    >
      <div
        css={css`
          font-family: FGMediumHankaku, sans-serif;
          font-size: 3em;
          padding: 8px;
        `}
        onKeyDown={() => {}}
        onClick={() => movePage(0)}
        role="none"
      >
        ササタニキカク
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        `}
      >
        <LinkIcon
          icon={faTwitter}
          clickAction={() =>
            openExternalLink("https://twitter.com/sasatanikikaku")
          }
        />
        <LinkIcon icon={faEnvelope} clickAction={() => movePage(2)} />
        <div
          css={css`
            margin-bottom: 10px;
          `}
        ></div>
      </div>
    </div>
  )
}

const mapStateToProp = state => ({
  main: state.main,
})

const mapDispatchToProps = dispatch => ({
  movePage: (...a) => dispatch(movePage(...a)),
})

export default connect(mapStateToProp, mapDispatchToProps)(LeftMenu)
