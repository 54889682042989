import {
  faJsSquare,
  faReact,
  faPython,
  faPhp,
  faGoogle,
  faNode,
} from "@fortawesome/free-brands-svg-icons"

export default [
  {
    text: "React",
    icon: faReact,
  },
  {
    text: "Node.js",
    icon: faNode,
  },
  {
    text: "Javascript",
    icon: faJsSquare,
  },
  {
    text: "Python",
    icon: faPython,
  },
  {
    text: "PHP",
    icon: faPhp,
  },
  {
    text: "Golang",
    icon: faGoogle,
  },
]
