/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useState, useEffect, useRef, useCallback } from "react"
import data from "./ContentData"
import shuffle from "lodash/shuffle"
import { useTransition, a } from "react-spring"
import { connect } from "react-redux"
import { movePage } from "../redux/reducers/main"
import { openExternalLink } from "../util/func"

const useMeasure = () => {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  )
  useEffect(() => {
    ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, bounds]
}

const useMedia = (queries, values, defaultValue) => {
  const match =
    useCallback(() => values[queries.findIndex(q => matchMedia(q).matches)], [
      queries,
      values,
    ]) || defaultValue

  const [value, set] = useState(match)
  useEffect(() => {
    const handler = () => set(match)
    window.addEventListener("resize", handler)
    return () => window.removeEventListener("resize", handler)
  }, [match])
  return value
}

const Contents = props => {
  const { movePage } = props
  // Hook1: Tie media queries to the number of columns
  const columns = useMedia(
    [
      "(min-width: 1500px)",
      "(min-width: 1000px)",
      "(min-width: 600px)",
      "(min-width: 50px)",
    ],
    [4, 3, 2, 1],
    1
  )
  // Hook2: Measure the width of the container element
  const [bind, { width }] = useMeasure()
  // Hook3: Hold items
  const [items, set] = useState(data)
  // Hook4: shuffle data every 2 seconds
  useEffect(() => {
    const handler = setInterval(() => set(shuffle), 30000)
    return () => clearInterval(handler)
  }, [])
  // Form a grid of stacked items using width & columns we got from hooks 1 & 2
  let heights = new Array(columns).fill(0) // Each column gets a height starting with zero
  let gridItems = items.map((child, i) => {
    const column = heights.indexOf(Math.min(...heights)) // Basic masonry-grid placing, puts tile into the smallest column using Math.min
    const xy = [
      (width / columns) * column,
      (heights[column] += child.height / 2) - child.height / 2,
    ] // X = container width / number of columns * column index, Y = it's just the height of the current column
    return {
      ...child,
      xy,
      width: width / columns,
      height: child.height / 2,
    }
  })
  // Hook5: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(gridItems, item => item.index, {
    from: ({ xy, width, height }) => ({
      xy,
      width,
      height,
      opacity: 0,
    }),
    enter: ({ xy, width, height }) => ({
      xy,
      width,
      height,
      opacity: 1,
    }),
    update: ({ xy, width, height }) => ({
      xy,
      width,
      height,
    }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  })
  return (
    <div
      {...bind}
      css={css`
        position: relative;
        font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
          helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
          sans-serif;
        width: 100%;
        height: 100%;
      `}
      style={{ height: Math.max(...heights) }}
    >
      {transitions.map(({ item, props: { xy, ...rest }, key }) => {
        let handleClick = e => {
          e.preventDefault()
          if (item.url) {
            openExternalLink(item.url)
          }
          if (item.page) {
            movePage(item.page)
          }
          e.stopPropagation()
        }
        return (
          <a.div
            key={key}
            css={css`
              position: absolute;
              will-change: transform, width, height, opacity;
              padding: 5px;
            `}
            style={{
              transform: xy.interpolate(
                (x, y) => `translate3d(${x}px,${y}px,0)`
              ),
              ...rest,
            }}
          >
            <div
              css={css`
                background-size: cover;
                background-position: center center;
                height: calc(100% - 20px);
                width: calc(100% - 20px);
                padding: 5px;
                overflow: hidden;
                line-height: 10px;
                border-radius: 4px;
                box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
                display: flex;
                justify-content: center;
                align-items: center;
              `}
              style={{ backgroundColor: item.color }}
              onClick={handleClick}
              onKeyDown={() => {}}
              role="none"
            >
              {item.content}
            </div>
          </a.div>
        )
      })}
    </div>
  )
}

const AboutUs = props => {
  const { movePage } = props
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 60px;
        width: calc(100% - 60px);
        height: 100%;
        overflow: auto;
        z-index: 100;
      `}
      onKeyDown={() => {}}
      onClick={() => movePage(2)}
      role="none"
    >
      <Contents movePage={movePage} />
    </div>
  )
}

const mapStateToProp = state => ({
  main: state.main,
})

const mapDispatchToProps = dispatch => ({
  movePage: (...a) => dispatch(movePage(...a)),
})

export default connect(mapStateToProp, mapDispatchToProps)(AboutUs)
