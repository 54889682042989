/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { TextField, Grid, Typography, Button } from "@material-ui/core"

const Form = props => {
  const { form, handleChange, toggleDialog, disabled } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">お問い合わせ</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="input-name"
          label="お名前"
          fullWidth
          defaultValue={form.name.value}
          required
          onBlur={e => handleChange(e, "name")}
          error={form.name.hasError}
          helperText={form.name.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="input-email"
          label="ご連絡用メールアドレス"
          fullWidth
          required
          defaultValue={form.email.value}
          onBlur={e => handleChange(e, "email")}
          error={form.email.hasError}
          helperText={form.email.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="input-message"
          label="ご用件"
          fullWidth
          rows={10}
          multiline
          required
          variant="outlined"
          defaultValue={form.message.value}
          onBlur={e => handleChange(e, "message")}
          error={form.message.hasError}
          helperText={form.message.message}
        />
      </Grid>
      <Grid
        item
        xs={12}
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Button color="primary" onClick={toggleDialog} disabled={disabled}>
          確認
        </Button>
      </Grid>
    </Grid>
  )
}

export default Form
