import React from "react"
import { useTransition, animated } from "react-spring"
import "./index.css"
import Cover from "../components/Cover.jsx"
import Contact from "../components/Contact.jsx"
import AboutUs from "../components/AboutUs.jsx"
import LeftMenu from "../components/LeftMenu"
import SEO from "../components/SEO"
import { connect } from "react-redux"

const pages = [<Cover />, <AboutUs />, <Contact />].map(i => ({ style }) => (
  <animated.div className="page" style={{ ...style }}>
    {i}
  </animated.div>
))

const Home = props => {
  const { pageIndex } = props
  const transitions = useTransition(pageIndex, null, {
    from: {
      opacity: 0,
      transform: "translate3d(0,100vh,0)",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0,0,0)",
    },
    leave: {
      opacity: 0.5,
      transform: "translate3d(0,-100vh,0)",
    },
  })

  return (
    <React.Fragment>
      <SEO />
      <div className="main">
        <LeftMenu />
        {transitions.map(({ item, props }) => {
          const Page = pages[item]
          return <Page key={item} style={props} />
        })}
      </div>
    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  pageIndex: state.main.pageIndex,
})

export default connect(mapStateToProp)(Home)
