/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import NavMenu from "./NavMenu"
import SVG from "./SVG"
import { UpDown, UpDownWide, UpDownReverse } from "../styles/animations"
import { connect } from "react-redux"
import { movePage } from "../redux/reducers/main"

const Cover = props => {
  const { movePage } = props
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 100;
      `}
      onKeyDown={() => {}}
      onClick={() => movePage(1)}
      role="none"
    >
      <NavMenu />
      <SVG icon="polygon" width={1800} color="#000000" left="0%" top="0%" />
      <UpDownWide>
        <SVG icon="hexa" width={100} color="#999999" left="5%" top="5%" />
        <SVG icon="circle" width={200} color="#999999" left="80%" top="80%" />
      </UpDownWide>
      <UpDownReverse>
        <SVG icon="ta" width={200} color="#F3FF00" left="27%" top="40%" />
        <SVG icon="ki" width={200} color="#FF00B2" left="51%" top="50%" />
        <SVG icon="ku" width={200} color="#00FFB1" left="75%" top="45%" />
      </UpDownReverse>
      <UpDown>
        <SVG icon="sa" width={200} color="#FF00F1" left="3%" top="60%" />
        <SVG icon="triangle" width={120} color="#FFFFFF" left="40%" top="3%" />
        <SVG icon="sa" width={200} color="#00FF14" left="15%" top="20%" />
        <SVG icon="ni" width={200} color="#FFA100" left="39%" top="70%" />
        <SVG icon="ka" width={200} color="#0200FF" left="63%" top="10%" />
      </UpDown>
    </div>
  )
}

const mapStateToProp = state => ({
  main: state.main,
})

const mapDispatchToProps = dispatch => ({
  movePage: (...a) => dispatch(movePage(...a)),
})

export default connect(mapStateToProp, mapDispatchToProps)(Cover)
